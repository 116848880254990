import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query LgcaHeaderQuery {
    craft {
      entries(section: "lgcaEc", site: "lgca", level: 1) {
        title
        uid
        slug
        url
        ... on Craft_lgcaEc_lgcaChapterCover_Entry {
          color0
          color1
          color2
        }
      }
      globalSet(handle: "header", site: "lgca") {
        ... on Craft_header_GlobalSet {
          name
          navHeading
          navCopy
          navCampaigns {
            ... on Craft_navCampaigns_campaign_BlockType {
              uid
              enabled
              campaign(site: "*") {
                url
                slug
                title
                ... on Craft_issue_issue_Entry {
                  heading0
                }
              }
            }
          }
          linkList {
            ... on Craft_linkList_action_BlockType {
              uid
              enabled
              action {
                url
              }
              linkText
            }
            ... on Craft_linkList_listLink_BlockType {
              uid
              enabled
              linkText
              listLink {
                url
              }
            }
          }
          secondaryNav {
            ... on Craft_secondaryNav_navLink_BlockType {
              uid
              enabled
              navLink {
                url
              }
              linkText
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { navHeading, navCopy, secondaryNav, linkList } = craft.globalSet;
  return {
    heading: navHeading,
    copy: navCopy,
    chapters: craft.entries.map(e => {
      return {
        uid: e.uid,
        text: e.title,
        url: e.url,
        colors: {
          primary: e.color0,
          secondary: e.color1,
          accent: e.color2,
        },
      };
    }),
    primaryNav: linkList.map(item => {
      return {
        uid: item.uid,
        link:
          item.action && item.action.length
            ? item.action[0].url
            : item.listLink?.url,
        text: item.linkText,
      };
    }),
    secondaryNav: secondaryNav.map(link => {
      return {
        uid: link.uid,
        link: link.navLink?.url,
        text: link.linkText,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
