import React, { useState, useEffect } from "react";

import { AppLink } from "@base";
import { LgcaSiteNav, PrimaryNav } from "@molecules";
import { Icon, Button, Container } from "@atoms";
import { useAppState } from "@state";
import useLgcaHeaderData from "@staticQueries/lgca/HeaderQuery";
import classNames from "classnames";

const LgcaHeader = ({ isEc }) => {
  const data = useLgcaHeaderData();

  const [{ layout, ec }, dispatch] = useAppState();
  const { hideNav, navOpen } = layout;
  const { menuColor: _menuColor, logoColor: _logoColor } = ec;
  const [menuColor, setMenuColor] = useState("dark");
  const [logoColor, setLogoColor] = useState("dark");

  useEffect(() => {
    setMenuColor(_menuColor);
    setLogoColor(_logoColor);
  }, [_menuColor, _logoColor]);

  const menuColors = {
    dark: {
      text: "text-black",
    },
    light: {
      text: "text-white",
    },
  };
  const logoColors = {
    dark: {
      text: "text-orange",
    },
    light: {
      text: "text-white",
    },
  };
  return (
    <>
      <LgcaSiteNav data={data} open={navOpen} />
      <header className="absolute left-0 right-0 top-0 z-40 sm:z-[100]">
        <Container
          className="flex items-center gap-6 py-6"
          variant={isEc ? "full" : "md"}
        >
          <AppLink
            className="relative -mt-2 flex h-12 items-center justify-center sm:-mt-4 sm:items-start"
            to="/"
            onClick={() => {
              dispatch({
                type: "setSlide",
                slide: 0,
              });
            }}
          >
            <Icon
              name="lgcaLogo"
              fitHeight
              className={classNames(
                "h-20 w-auto p-2 transition duration-200 sm:h-24 sm:h-24 lg:h-28",
                {
                  [logoColors[logoColor].text]: !navOpen,
                  "text-orange sm:text-white": navOpen,
                }
              )}
            />
          </AppLink>
          <div className="font-extended font-sans ml-auto ml-auto hidden text-white lg:flex">
            <PrimaryNav
              links={data.primaryNav}
              inverse={menuColor === "light" && !navOpen}
            />
          </div>
          <div
            className={classNames(
              menuColors[navOpen ? "dark" : menuColor].text,
              "ml-auto flex items-center text-sm font-bold uppercase transition duration-200 lg:ml-0",
              {
                "pointer-events-none opacity-0": hideNav,
              }
            )}
          >
            <div className="mx-2 xxs:hidden">
              <Button
                size="sm"
                to={data?.button?.link || "https://action.greencal.org"}
              >
                {data?.button?.text || "Take Action"}
              </Button>
            </div>
            <div className="ml-2">
              <button
                type="button"
                className="relative ml-2 inline-flex items-center"
                onClick={() => {
                  dispatch({ type: "toggleNav" });
                }}
              >
                {/* <span className="xxs:hidden">Menu</span> */}
                <Icon
                  name="bars"
                  className={`${
                    navOpen ? "opacity-0" : "opacity-100"
                  } h-8 w-8 transition duration-200`}
                />
                <Icon
                  name="close"
                  className={`${
                    navOpen ? "opacity-100" : "opacity-0"
                  } absolute inset-0 h-8 w-8 transition duration-200`}
                />
              </button>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

LgcaHeader.defaultProps = {};

export default LgcaHeader;
